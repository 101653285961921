<template>
  <div>
 <!-- <div class="title">
      <div class="container box-series" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
        <div class="icon-text-wrapper">
          <img src="@/assets/icons/back.svg"  alt @click="initialRoute" /> -->
          <!-- <img src="@/assets/icons/back.svg" class="rotate-img"  @click="initialRoute" alt /> -->
          <!-- &nbsp;&nbsp;&nbsp;
          <p v-if="screen && screen.title" style="margin: 0px 10px">{{ localDisplayLang === "ara" ? screen.title.ar : screen.title.en }}</p>
        </div>
      </div>
    </div> -->
    <!-- <div class="slider-header" :dir="localLang === 'ara' ? 'rtl' : 'ltr'">
      <div class="slider-title">
        <span class="title">{{$t("Search results")}}</span>
      </div>
     
    </div> -->
    <div
      :id="`${contents.title}-category-search-contents-new`"
      :dir="localLang === 'ara' ? 'rtl' : 'ltr'"
    >
      <div class="categorised-search-contents">
        <div class="search-contents-box">
          <categoryCard :content="contents" :cardType="cardDisplayType" v-if="contents"></categoryCard>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { tns } from "tiny-slider/src/tiny-slider";
export default {
  props: {
    contents: {
      type: Object
    },
    localLang: {
      type: String
    }
  },
  data() {
    return {
      cardDisplayType: null,
      localDisplayLang: null,
			routeSearchQuery: null
    };
  },
  created() {
     console.log("contents in search carousel", this.contents);
		this.routeSearchQuery = this.$route.params.query;
    this.fetchCardType();
    //event for card size check for every screen sizes
    eventBus.$on("re-render-carousel", () => {
      this.fetchCardType();
    });
  },
  mounted() {
    setTimeout(() => {
      this.constructCarousel();

    }, 2000)
  },
  methods: {
    fetchCardType() {
      if (screen.width <= 375) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "90px",
          width: "160px",
          playButton: { enablePlay: false }
        };
      } else if (screen.width > 375 && screen.width <= 425) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "100px",
          width: "170px",
          playButton: { enablePlay: false }
        };
      } else if (screen.width > 425 && screen.width <= 500) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "110px",
          width: "180px",
          playButton: { enablePlay: false }
        };
      } else if (screen.width > 490 && screen.width <= 590) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "130px",
          width: "200px",
          playButton: { enablePlay: false }
        };
      } else if (screen.width > 590 && screen.width <= 690) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "115px",
          width: "185px",
          playButton: { enablePlay: false }
        };
      } else if (screen.width > 690 && screen.width <= 800) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "145px",
          width: "215px",
          playButton: { enablePlay: false }
        };
      } else if (screen.width > 800 && screen.width < 1050) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "120px",
          width: "210px",
          playButton: { enablePlay: false }
        };
      } else {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "155px",
          width: "290px",
          playButton: { enablePlay: false }
        };
      }
    },
    constructCarousel() {
      let data = {
        container: `#${this.contents.title}-category-search-contents-new`,
        controlsContainer: `#${this.contents.title}-search-slide-controls`,
        nav: false,
        loop: false,
        slideBy: "page",
        gutter: 5,
        responsive: {
          320: {
            items: 2,
            edgePadding: 5,
            gutter: 5
          },
          576: {
            items: 3,
            edgePadding: 5,
            gutter: 5
          },
          900: {
            edgePadding: 5,
            gutter: 7,
            items: 4
          }
        }
      };
      this.$nextTick(() => {
        this.slider = tns(data);
      });
    }
  },

  components: {
    categoryCard: () =>
      import(
        /* webpackChunkName: "categorycard" */ "@/components/Templates/categoryCard.vue"
      )
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "node_modules/tiny-slider/src/tiny-slider.scss";
@import "./SearchedContentsCarousel.scss"
</style>